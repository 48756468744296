<template>
  <svg class="circular-chart" viewBox="0 0 36 36">
    <path
      class="circle-bg"
      :class="circleClass"
      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      stroke-dasharray="100, 100"
    />

    <path
      class="circle"
      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      :stroke-dasharray="`${percentage}, 100`"
      :style="cssVars"
    />

    <text v-if="!hasIcon" class="percentage" x="18" y="22.35">
      {{ innerText || percentage }}
    </text>
    <slot />
  </svg>
</template>

<script>
export default {
  name: 'CircleLoader',
  props: {
    /** Texto */
    innerText: {
      type: String,
      default: () => null,
    },
    /* Valor Porcentual de 0-100 */
    percentage: {
      type: Number,
      default: () => 0,
    },
    /** Clase del circulo */
    circleClass: {
      type: String,
      default: () => '',
    },
    /** Se habilita o desahabilita el mostrar el texto o el icono para mostrar el contenido del slot correctamente */
    hasIcon: {
      type: Boolean,
      default: () => false,
    },
    loaderColor: {
      type: String,
      default: '#4cc790',
    },
  },
  computed: {
    cssVars() {
      return {
        '--custom-loader-stroke-color': this.loaderColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle {
  stroke: var(--custom-loader-stroke-color);
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.8em;
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
</style>
