<template>
  <div class="total-amount-view d-flex justify-content-start">
    <em class="total-amount-view__icon icon-block" :class="[icon, iconColor]" />
    <div class="d-flex flex-column">
      <div
        class="total-amount-view__title color-secondary-6 font-14"
        :class="[titleClass]"
      >
        {{ title }}
      </div>
      <div
        class="total-amount-view__amount color-black font-weight-bold"
        :class="amountClass"
      >
        {{ amount }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TotalAmountView',
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: () => 'color-green',
    },
    amountClass: {
      type: String,
      default: () => 'font-25',
    },
    titleClass: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style scoped lang="scss">
.icon-block {
  font-size: 28px;
  margin-right: 12px;
  margin-top: 4px;
}
</style>
