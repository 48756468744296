<template>
  <div class="row">
    <div
      v-if="topicText"
      class="col-1 d-flex align-items-center"
      :class="topicTextClass"
    >
      {{ topicText }}
    </div>
    <div
      v-if="isMobile"
      class="row col font-weight-bold color-black font-12 mx-auto my-3"
    >
      <div class="col-12 d-flex justify-content-between row mx-auto rounded bg-light px-0 py-2 ">
        <action-button
          i="fas fa-chevron-left"
          variant="custom-prev"
          class="text-custom-prev"
          @click="onPrevHandler"
        />
        <div
          v-for="(item, index) in historialItemsWithPagination"
          :key="`carouserl-item-${index}`"
          class="col mx-2 px-4"
          :class="classObject({ item, index })"
        >
          <div
            class="pt-2 header d-flex flex-column justify-content-center align-items-center"
          >
            <div class="d-block">{{ item.title }}</div>
            <div class="d-block">{{ item.subtitle }}</div>
          </div>
          <div
            class="checkbox-container d-flex justify-content-center align-items-center ml-2 mb-2"
            :class="checkboxContainerClass"
          >
            <input
              class="mt-2"
              type="radio"
              :id="`accordion-cb-${index}`"
              name="accordion-cb"
              :checked="item.isChecked"
              :value="item"
              @input="emitValue(item)"
              :disabled="item.isDisabled || item.isBlocked"
            />
          </div>
        </div>
        <action-button
          i="fas fa-chevron-right"
          variant="custom-next"
          class="text-custom-next"
          @click="onNextHandler"
        />
      </div>
    </div>
    <div
      v-else
      class="row font-weight-bold color-black font-12"
      :class="topicText ? 'col-11' : 'col-12'"
    >
      <div
        v-for="(item, index) in historialItems"
        :key="index"
        class="cube col-1 px-0"
        :class="classObject({ item, index })"
      >
        <div
          class="pt-2 header d-flex flex-column justify-content-center align-items-center"
        >
          <div class="d-block">{{ item.title }}</div>
          <div class="d-block">{{ item.subtitle }}</div>
        </div>
        <div
          class="checkbox-container d-flex justify-content-center align-items-center ml-2 mb-2"
          :class="checkboxContainerClass"
        >
          <input
            class="mt-2"
            type="radio"
            :id="`accordion-cb-${index}`"
            name="accordion-cb"
            :checked="item.isChecked"
            :value="item"
            @input="emitValue(item)"
            :disabled="item.isDisabled || item.isBlocked"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from 'ActionButton';
const DEFAULT_INCREASE_PAGINATION_SIZE = 3;

export default {
  name: 'SingleHistorialAccordion',
  components: { ActionButton },
  props: {
    topicTextClass: {
      type: String,
      default: '',
      meta: {
        description: 'Clase para nombre de contexto',
        example: 'object-class',
      },
    },
    topicText: {
      type: String,
      default: '',
      meta: {
        description: 'Nombre de contexto',
        example: 'Prueba',
      },
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
      meta: {
        description:
          'Arreglo de objetos para seleccionar elementos del histórico',
        example: '{ title, subtitle, isChecked, isDisabled }',
      },
    },
    cubeClass: {
      type: String,
      default: '',
      meta: {
        description: 'Clase para caja de acordeón',
        example: 'object-class',
      },
    },
    checkboxContainerClass: {
      type: String,
      default: '',
      meta: {
        description: 'Clase para contenedor de checkbox',
        example: 'object-class',
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
      meta: {
        description: 'Modo dimension móvil',
        example: true,
      },
    },
  },
  data() {
    return {
      historialItems: [],
      currentPagination: 0,
    };
  },
  methods: {
    emitValue(item) {
      this.historialItems.forEach((x) => (x.isChecked = false));
      item.isChecked = true;
      this.$emit('on-checked-historial-item', item);
    },
    onPrevHandler() {
      if (this.currentPagination <= 0) return;
      this.currentPagination -= DEFAULT_INCREASE_PAGINATION_SIZE;
    },
    onNextHandler() {
      const nextCount = this.currentPagination + DEFAULT_INCREASE_PAGINATION_SIZE;
      if (nextCount >= this.historialItems.length) return;
      this.currentPagination = nextCount;
    },
  },
  computed: {
    classObject() {
      return ({ item, index }) => {
        let isFirstElement = index === 0;
        let isLastElement = this.historialItems.length === index + 1;

        return {
          ...this.cubeClass,
          'cube-unchecked': !item.isChecked,
          'cube-unchecked-mobile': !item.isChecked && this.isMobile,
          'cube-checked': item.isChecked,
          'cube-disabled': item.isDisabled,
          'cube-blocked': item.isBlocked,
          'is-mobile': this.isMobile,
          'first-item': isFirstElement && !this.isMobile,
          'last-item': isLastElement && !this.isMobile,
        };
      };
    },
    historialItemsWithPagination() {
      return this.historialItems
        .slice(this.currentPagination, this.currentPagination + DEFAULT_INCREASE_PAGINATION_SIZE);
    },
  },
  watch: {
    items() {
      this.historialItems = [...this.items];
    },
  },
};
</script>
<style lang="scss" scoped>
$lightOrange: #fde9e5;
$orange: #ff7058;
$lightGray: #f7f7f7;
$whiteSmoke: #e2e2e2;
$blockedGray: #b8c3bc;
$blockedBorderGray: #9aa6a8;
$disabledTextGray: #e2e2e2;
$disabledLightGray: #c3c3c3;

.checkbox-container > input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0px;
  font: inherit;
  width: 1.45em;
  height: 1.45em;
  border: 0.1em solid $disabledTextGray;
  border-radius: 50%;
}
.cube-unchecked {
  border: 1px solid $whiteSmoke;
  border-width: 1px 1px 1px 0px;
}
.cube-unchecked-mobile {
  border: 1px solid $whiteSmoke;
  border-width: 1px 1px 1px 1px;
}

.cube-checked {
  border: 1px solid $orange;
  background-color: $lightOrange;
}
.cube-checked .checkbox-container > input[type='radio']:checked {
  color: $orange;
  border: 0.4em solid $orange;
}
.cube-disabled {
  border: 1px solid $whiteSmoke;
  border-width: 1px 1px 1px 0px;
  color: $disabledLightGray;
  background-color: $lightGray;
}
.cube-disabled .checkbox-container > input[type='radio'] {
  color: $disabledTextGray;
  background-color: $disabledTextGray;
}
.cube-blocked {
  border: 1px solid $blockedBorderGray;
  background-color: $blockedGray;
}
.cube-blocked .checkbox-container > input[type='radio'] {
  border: 1px solid $blockedGray !important;
  background-size: cover !important;
  background-image: url("data:image/svg+xml,%3Csvg id='Component_547_1' data-name='Component 547 – 1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Rectangle_2495' data-name='Rectangle 2495' width='20' height='20' rx='10' fill='%23455a5f'/%3E%3Cpath id='xmark' d='M7.776,102.62a.8.8,0,1,1-1.132,1.132l-2.638-2.637-2.638,2.636a.8.8,0,0,1-1.133-1.133L2.873,99.98.235,97.343A.8.8,0,0,1,1.368,96.21l2.638,2.64,2.639-2.639a.8.8,0,1,1,1.133,1.133L5.138,99.982Z' transform='translate(5.994 -89.981)' fill='%23e8ebe9'/%3E%3C/svg%3E%0A") !important;
}
.menu ::v-deep {
  .custom-icon-view {
    .dot {
      padding: 1rem;
    }
  }
}
.first-item {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-width: 1px 1px 1px 1px;
}
.last-item {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.is-mobile {
  border-radius: 6px;
}
::v-deep .btn-custom-next, .btn-custom-prev {
  background: transparent;
}
::v-deep .text-custom-next, .text-custom-prev {
  color: #FF7058 !important;
} 
</style>
