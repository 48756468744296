<template>
  <div class="mt-2">
    <custom-callout :has-border="false" :has-toggle="false">
      <div class="row col-12">
        <div class="row col-10">
          <div class="row col-12 col-md-6">
            <div class="col-12">
              <span class="data-title d-block border-mobile">Documento:</span>
              <span class="font-16 color-black">
                {{ documentName | empty }}
              </span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="col-12">
              <span class="data-title d-block border-mobile">Archivo:</span>
              <p class="font-16 color-black">
                {{ documentFileName | empty }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-end">
          <action-button
            i="fas fa-file-download px-2 mx-0"
            v-if="documentId"
            variant="success px-1 mx-1"
            @click="onDownload(documentId)"
          />
        </div>
      </div>
    </custom-callout>
  </div>
</template>

<script>
import CustomCallout from 'custom-callout';
import ActionButton from 'ActionButton';
import documentService from '@/services/documents';

export default {
  name: 'ServiceAlertView',
  components: {
    CustomCallout,
    ActionButton,
  },
  props: {
    documentName: {
      type: String,
      default: () => '',
    },
    documentFileName: {
      type: String,
      default: () => '',
    },
    documentId: {
      type: String,
      default: '',
    },
  },
  methods: {
    async onDownload(documentId) {
      await documentService.downloadFromServer(documentId);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
