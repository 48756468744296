const monthsString = {
  ENE: 'ENE',
  FEB: 'FEB',
  MAR: 'MAR',
  ABR: 'ABR',
  MAY: 'MAY',
  JUN: 'JUN',
  JUL: 'JUL',
  AGO: 'AGO',
  SEPT: 'SEPT',
  OCT: 'OCT',
  NOV: 'NOV',
  DIC: 'DIC',
}

const monthsNumber = {
  ENE: 1,
  FEB: 2,
  MAR: 3,
  ABR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AGO: 8,
  SEPT: 9,
  OCT: 10,
  NOV: 11,
  DIC: 12,
}

export {
  monthsString,
  monthsNumber,
}