<template>
  <div>
    <page-header
      :page="{
        icon: 'fas fa-user-circle',
        title: 'Solicitud de Reembolso',
        routes: [
          'Inicio',
          'Estudiante',
          'Manejo de Reembolso y Pago Directo',
          'Solicitar Reembolso',
        ],
      }"
    >
      <template #customActions>
        <div
          class="d-flex"
          :class="{
            'justify-content-end': !isMobile,
            'justify-content-between w-100 row mx-auto': isMobile,
          }"
        >
          <action-button
            :class="{ 'col p-2': isMobile }"
            i="fas fa-arrow-circle-left"
            text="Regresar"
            variant="outline-success"
            @click="onBack"
          />
          <action-button
            v-if="canShowSubmitAction"
            :class="{ 'col p-2': isMobile }"
            i="fas fa-flag-alt"
            text="Someter Reembolso"
            variant="success ml-3"
            @click="() => submit()"
          />
        </div>
      </template>
    </page-header>

    <template v-if="!isSubmitted">
      <ValidationObserver ref="formRef">
        <div
          v-if="canShowSubmitAction && !isNewStatus"
          class="container-reason"
        >
          <b>Razón de devolución:</b>
          <p>
            {{ infoData.statusComment }}
          </p>
        </div>
        <section-title
          id="reinbursement-selection-title"
          class="mt-5"
          has-line
          title="Selección de período para reembolso"
        />
        <historial-accordion
          id="reinbursement-period"
          checkbox-container-class="pr-2 pb-1"
          class="mt-3"
          :is-mobile="isMobile"
          :items="historialItems"
          topic-text="Periodo:"
          :topic-text-class="
            `font-weight-bold font-18 ${isMobile ? 'col-12' : ''}`
          "
          @on-checked-historial-item="updateItems"
        />
        <board
          class="mt-3"
          v-if="isSelectedPeriod"
          :details="requestBoardDetails"
        />
        <div v-else>
          <CustomError
            message="Para visualizar los servicios favor de seleccionar el período correspondiente"
            is-alert-mode
          />
        </div>
        <div v-if="isSelectedPeriod">
          <section-title
            id="reinbursement-service-amount-title"
            class="mt-4"
            has-line
            title="Información del Reembolso"
          />
          <div class="row menu rounded jumbo bg-light mt-3 p-4 mx-auto">
            <label class="color-black font-16 col-12">
              Servicios autorizados por la Secretaría Asociada de Educación
              Especial.
            </label>
            <div class="mt-1 mb-3 col-12">
              <div v-if="showNotServicesAvaiableMessage" class="mb-2">
                <CustomError
                  message="Para el período seleccionado no se encontró una consulta vigente"
                  variant="no-border"
                />
              </div>
              <request-services
                :is-mobile="isMobile"
                :items="infoData.services"
                :read-only="false"
                :selected-items="infoData.selectedServices"
                @on-select-services="onSelectServiceHandler"
                @update-amount="onUpdateAmountHandler"
              />
            </div>
            <validation-provider
              v-slot="{ errors }"
              class="col-12 px-0"
              :custom-messages="{
                required_if: 'El monto a reembolsar es requerido.',
              }"
              :rules="{ required_if: !infoData.amount }"
            >
              <CustomError
                v-if="errors[0] && errors[0].length > 0"
                :message="errors[0]"
                variant="no-border"
              />
            </validation-provider>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver v-if="isSelectedPeriod" ref="documentFormRef">
        <section-title
          id="reinbursement-document-title"
          class="mt-5"
          has-line
          title="Documentos"
        />
        <template v-for="(item, index) in infoData.documents">
          <validation-provider
            v-if="!isReimbursementCertification(item.documentTypeId)"
            :key="`doc-validate-${index}`"
            v-slot="{ errors }"
            :custom-messages="{
              required_if: docValidationMessage(item),
            }"
            :disabled="isOther(item) ? 'disabled' : false"
            :rules="{ required_if: item.documentId || !item.file }"
          >
            <reinbursement-documents
              :key="index"
              :is-mobile="isMobile"
              :item="item"
              :request-id="requestId"
              @on-refresh-data="onUpdateDocument"
            />
            <CustomError
              v-if="errors[0] && errors[0].length > 0"
              :message="errors[0]"
              variant="no-border"
            />
          </validation-provider>
        </template>
      </ValidationObserver>
    </template>
    <div v-else class="col-12">
      <board :details="requestBoardDetails" />
      <custom-tab
        v-if="isSubmitted"
        class="custom-nav-container custom-nav-colors font-18"
        content-class="mt-3 pb-5 font-18"
        :default-active-tab="activeTab"
        :nav-class="'font-18'"
        :no-nav-style="true"
      >
        <custom-tab-item
          :active="isSummaryTabActive"
          class="mt-3"
          lazy
          title="Resumen"
        >
          <div class="row mt-4 mb-5 ml-2">
            <div class="col-md-4 col col-sm-6">
              <total-amount-view
                :amount="getStringAmount | price"
                icon="fa fa-usd-circle"
                :icon-color="'color-yellow-star'"
                title="Reembolso Solicitado:"
              />
            </div>
          </div>
          <div>
            <section-title :has-line="true" title="Resumen General" />
            <custom-callout
              class="mb-4 custom-callout bg-white"
              :has-border="false"
              :has-toggle="false"
            >
              <div class="row py-2 mr-md-0 align-items-start">
                <div class="col-md-1 mt-5">
                  <div
                    class="
                      d-flex
                      flex-column
                      font-weight-bold
                      mt-n5
                      ml-n4
                      mt-sm-n5
                      ml-sm-n4
                      align-items-center
                    "
                  >
                    <span
                      class="
                        summary-dot
                        d-flex
                        justify-content-center
                        align-items-center
                        py-2
                        px-1
                        mt-2
                      "
                    >
                      <i
                        class="
                          orange-icon orange-icon-badge-align
                          fas
                          fa-donate
                        "
                      />
                    </span>
                  </div>
                </div>
                <div class="col-md-11">
                  <div class="row mb-3">
                    <div class="col-12">
                      <span
                        class="
                          data-title
                          d-block
                          border-mobile
                          font-weight-bold
                        "
                      >
                        Periodo a Reembolsar:
                      </span>
                      <div class="d-flex">
                        <div
                          v-for="(period, index) in infoData.periods"
                          :key="`period-${index}`"
                          class="round-element mx-1"
                        >
                          <span
                            class="
                              font-16
                              color-black
                              text-nowrap
                              font-weight-bold
                            "
                          >
                            {{ period.periodName | uppercase }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <span class="data-title d-block border-mobile">
                        Desglose de los gastos solicitados en el reembolso para
                        el periodo seleccionado:
                      </span>
                      <p
                        v-if="infoData && infoData.selectedServices.length == 0"
                        class="font-14 color-black"
                      >
                        {{ infoData.comment }}
                      </p>
                      <request-services
                        :is-mobile="isMobile"
                        :items="infoData.selectedServices"
                        :read-only="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </custom-callout>
          </div>
          <div>
            <section-title :has-line="true" title="Documentos" />
            <div
              v-for="(document, index) in infoData.documents"
              :key="`document-${index}`"
            >
              <DocumentView
                v-if="!isReimbursementCertification(document.documentTypeId)"
                :document-file-name="document.documentFilename"
                :document-id="document.documentId"
                :document-name="document.documentTypeName"
              />
            </div>
          </div>
        </custom-tab-item>
        <custom-tab-item
          v-if="showPaymentTab"
          :active="isPaymentTabActive"
          class="mt-3"
          lazy
          title="Pago"
        >
          <div class="row mx-auto">
            <div
              class="col-12 p-0 d-flex justify-content-end align-items-center"
            >
              <div class="mr-3" style="width: 60px; height: 60px">
                <circle-loader
                  v-if="paymentPercentage < 100"
                  circle-class="custom-circle-loader"
                  has-icon
                  icon-class="green-color"
                  :percentage="paymentPercentage"
                >
                  <text
                    :fill="
                      paymentPercentage == 100
                        ? 'background-green #000000'
                        : '#E5F5EF'
                    "
                    font-family="Font Awesome\ 5 Free"
                    font-size="18"
                    font-weight="900"
                    x="8"
                    y="25.35"
                  >
                    &#xf00c;
                  </text>
                </circle-loader>
                <i v-else class="fas fa-check-circle fa-3x green-color" />
              </div>
              <div class="ml-2 mt-2">
                <div class="color-secondary-6 font-14">
                  Monto Total:
                </div>
                <div>
                  <div>
                    <span class="color-green font-weight-bold font-22">
                      {{ totalPurchase | price }}
                    </span>
                    /
                    <span class="color-secondary-6 font-16">
                      {{ infoData.amountToRefund | price }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 row mx-auto p-0">
              <section-title
                class="col-12 p-0"
                icon="fas fa-dollar-sign text-white"
                reverse
                title="Adjudicación por Cifra de Cuenta"
              />
              <div
                class="col-12 row mx-auto d-flex align-items-center bg-gray-3 rounded mt-3 px-5 py-4"
              >
                <i
                  class="fas fa-usd-square custom-icon d-flex justify-content-center align-items-center rounded-circle bg-primary-1 text-white"
                />
                <div class="col-md-3">
                  <span class="custom-label text-gray mx-2 font-16">
                    Voucher ID:
                  </span>
                  <span class="custom-value text-black font-16">
                    {{ paymentInfo.voucherNumber | empty }}
                  </span>
                </div>
                <div class="col p-0">
                  <span class="custom-label text-gray ml-1 mr-2 font-16">
                    Payment ID:
                  </span>
                  <span class="custom-value text-black font-16">
                    {{ infoData.paymentId | empty }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </custom-tab-item>
      </custom-tab>
    </div>
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import moment from 'moment';
import {
  getRequestDetails,
  getRequestDetailsByTapRequest,
  submitRequest as _submitRequest,
  getPaymentTabInfo as _getPaymentTabInfo,
  getServicesByPeriod as _getServicesByPeriod,
  getBlockedPeriods as _getBlockedPeriods,
} from '@/services/api/disbursement.api.js';
import SectionTitle from 'SectionTitle';
import HistorialAccordion from '@/components/common/SingleHistorialAccordion.vue';
import { monthsString, monthsNumber } from '@/enums/months';
import CustomError from 'custom-error';
import ActionButton from 'ActionButton';
import Board from 'Board';
import { CustomTab, CustomTabItem } from 'custom-tabs';
import TotalAmountView from './components/TotalAmountView.vue';
import CustomCallout from 'custom-callout';
import voucherStatus from '@/utils/constants/voucherStatus';
import DocumentView from './components/DocumentView.vue';
import RequestServices from './components/RequestServices.vue';
import alertMixin from '@/mixins/alert.mixin';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ReinbursementDocuments from './components/ReinbursementDocuments.vue';
import codeDocumentTypes from '@/utils/constants/documentTypes';
import disbursementRequestStatuses from '@/utils/constants/student/disbursementRequestStatuses';
import CircleLoader from '@/components/common/loaders/CircleLoader.vue';
import store from '@/store/index';
import { serialize } from 'object-to-formdata';

const STATUS_NAME = {
  sometido: 'Sometido',
  SometidoRevisado: 'Sometido Revisado',
};

const TAB_INDEXES = {
  Summary: 0,
  Payment: 1,
};
export default {
  name: 'ReinbusementMain',
  components: {
    PageHeader,
    ActionButton,
    SectionTitle,
    CustomError,
    HistorialAccordion,
    CircleLoader,
    Board,
    CustomTab,
    CustomTabItem,
    TotalAmountView,
    CustomCallout,
    DocumentView,
    ValidationObserver,
    ValidationProvider,
    ReinbursementDocuments,
    RequestServices,
  },
  mixins: [alertMixin],
  data() {
    return {
      infoData: {
        requestId: 0,
        requestNumber: null,
        tapRequestNumber: null,
        determination: null,
        statusId: 0,
        statusName: null,
        academicYearId: 0,
        academicYearDescription: null,
        amount: 0,
        periods: null,
        documents: null,
        comment: null,
        selectedServices: [],
      },
      paymentInfo: {
        voucherNumber: null,
        voucherStatus: null,
        billingPurchaseOrderNumbers: [],
      },
      historialItems: [],
      file: null,
      keyquantity: 1,
      wasFormValidated: false,
      activeTab: 0,
      showNotServicesAvaiableMessage: false,
      isSelectedPeriod: false,
    };
  },
  computed: {
    isMobile() {
      return store.getters.getIsMobileDensity;
    },
    requestId() {
      return +this.$route.params.requestId;
    },
    tapRequestId() {
      return +this.$route.params.tapRequestId;
    },
    checkPeriods() {
      return this.historialItems.filter((i) => i.isChecked);
    },
    isSubmitted() {
      return (
        this.infoData.statusId !== disbursementRequestStatuses.Nuevo &&
        this.infoData.statusId !== disbursementRequestStatuses.DevueltoARevisión
      );
    },
    isNewStatus() {
      return this.infoData.statusId === disbursementRequestStatuses.Nuevo;
    },
    canShowSubmitAction() {
      return (
        this.infoData.statusId === disbursementRequestStatuses.Nuevo ||
        this.infoData.statusId === disbursementRequestStatuses.DevueltoARevisión
      );
    },
    totalAmount() {
      return Number(this.infoData?.amount ?? 0);
    },
    getStringAmount() {
      return this.totalAmount.toString();
    },
    startDate() {
      const date = this.infoData?.assignmentStartDate;
      return date ? moment(date) : '';
    },
    endDate() {
      const date = this.infoData?.assignmentEndDate;
      return date ? moment(date) : '';
    },
    requestBoardDetails() {
      let items = [
        {
          label: 'Año Académico:',
          icon: 'fas fa-calendar-edit',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.academicYearDescription,
          onlySubmited: true,
        },
        {
          label: 'Número de Solicitud:',
          icon: 'fas fa-file-invoice',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.requestNumber,
          onlySubmited: true,
        },
        {
          label: 'Estatus:',
          icon: 'fas fa-ballot-check',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.statusName,
          onlySubmited: true,
        },
        {
          label: 'Número Consulta:',
          icon: 'fas fa-file-invoice',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.tapRequestNumber,
          onlySubmited: false,
        },
        {
          label: 'Determinación:',
          icon: 'fas fa-ballot-check',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.determination,
          onlySubmited: false,
        },
      ];
      if (this.startDate)
        items.push({
          label: 'Vigencia:',
          icon: 'fas fa-clock',
          cols: 'col-md-4 pt-2',
          data: this.infoData?.assignmentDateToShow,
          onlySubmited: false,
        });
      if (!this.isSubmitted) {
        items = items.filter((x) => !x.onlySubmited);
      }
      return [items];
    },
    isCheckedPeriodWithinEffectiveDate() {
      if (!this.checkPeriods.length) return false;
      const period = this.checkPeriods[0];
      const periodDate = moment({
        year: Number(period.subtitle),
        month: period.monthNumber - 1,
      });
      periodDate.set('date', periodDate.daysInMonth());
      return periodDate >= this.startDate && periodDate <= this.endDate;
    },
    isReimbursementCertification() {
      return (documentTypeId) =>
        documentTypeId === codeDocumentTypes.ReimbursementCertification;
    },
    isSummaryTabActive() {
      return this.activeTab === TAB_INDEXES.Summary;
    },
    isPaymentTabActive() {
      return this.activeTab === TAB_INDEXES.Payment;
    },
    showPaymentTab() {
      return (
        this.infoData.statusId === disbursementRequestStatuses.Pagado ||
        this.infoData.statusId === disbursementRequestStatuses.EnviadoAFinanzas
      );
    },
    paymentPercentage() {
      return (this.totalPurchase / this.infoData.amountToRefund) * 100;
    },
    totalPurchase() {
      let total = 0;
      if (this.paymentInfo.voucherStatus == voucherStatus.Cancelled) {
        return total;
      }

      this.paymentInfo.billingPurchaseOrderNumbers.map(
        (item) => (total += item.amount)
      );
      return total;
    },
    hasFoundServices() {
      return this.infoData?.services?.length > 0;
    },
  },
  async mounted() {
    await this.refreshData();
    await this.getBlockedPeriods();
    this.loadSelectionPeriod();
    this.getPaymentInformation();

    if (this.checkPeriods.length) {
      const period = this.checkPeriods[0];
      await this.getServicesByPeriod(period.monthNumber, period.subtitle);
      this.isSelectedPeriod = true;
    }
  },
  methods: {
    async getBlockedPeriods() {
      var { data } = await _getBlockedPeriods(
        this.infoData.studentSieId,
        this.infoData.requestId,
        this.infoData.tapRequestId
      );
      this.infoData.blockedPeriods = data;
    },
    async onBack() {
      this.$router.push({
        name: 'history-location',
        params: {
          studentSieId: this.infoData.studentSieId,
          from: 'reinbursement',
        },
      });
    },
    onUpdateAmountHandler(newTotal) {
      this.infoData.amount = Number(newTotal);
    },
    onSelectServiceHandler(val) {
      this.infoData.selectedServices = val?.map((item) => {
        return {
          ...item,
          disbursementRequestId: this.infoData.requestId,
          tapCentralServiceId: item.tapCentralServiceId,
        };
      });
    },
    docValidationMessage(item) {
      switch (item.documentTypeId) {
        case codeDocumentTypes.PaymentCertificate:
          return 'El documento de certificado de servicios es requerido.';
        case codeDocumentTypes.EvidencePaymentReceipt:
          return 'El documento de evidencia recibo de pago es requerido.';
        default:
          return '';
      }
    },
    isOther(item) {
      return item.documentTypeId === codeDocumentTypes.Other;
    },
    async updateItems(item) {
      this.isSelectedPeriod = true;
      if (!this.isCheckedPeriodWithinEffectiveDate) {
        this.ShowToast(
          'Alerta',
          'No es posible seleccionar el periodo, ya que no se encuentra dentro de la vigencia de la consulta.',
          'error'
        );
        this.historialItems.forEach((x) => (x.isChecked = false));
      }

      await this.getServicesByPeriod(item.monthNumber, item.subtitle);
    },
    async getServicesByPeriod(month, year) {
      this.showNotServicesAvaiableMessage = false;
      year = Number(year);

      const { data } = await _getServicesByPeriod(
        this.infoData.studentSieId,
        month,
        year
      );
      this.infoData.services = data;

      // show error message if no services are available
      if (data.length === 0) this.showNotServicesAvaiableMessage = true;
    },
    async getPaymentInformation() {
      try {
        const { billId } = this.infoData;
        if (!billId) return;

        const { data } = await _getPaymentTabInfo(billId);
        if (data.some((s) => s.isPartial)) {
          this.paymentInfo.voucherNumber = data[data.length - 1].voucherNumber;
        } else if (data[0] != undefined) {
          const paymentInfo = data[0];
          this.paymentInfo.billingPurchaseOrderNumbers =
            paymentInfo.billingPurchaseOrderNumbers;
          this.paymentInfo.voucherNumber = paymentInfo.voucherNumber;
          this.paymentInfo.voucherStatus = paymentInfo.voucherStatus;
        }
      } catch (e) {
        this.ShowToast(
          '',
          'Error al obtener los datos, detalles: ' + e.message,
          'error'
        );
      }
    },
    async refreshData() {
      if (this.requestId > 0) {
        const { data } = await getRequestDetails(this.requestId);
        this.infoData = data;
      } else {
        const { data } = await getRequestDetailsByTapRequest(this.tapRequestId);
        this.infoData = data;
      }
    },
    loadSelectionPeriod() {
      const years = this.infoData.academicYearDescription?.split('-');
      const { periods, blockedPeriods } = this.infoData;
      const currentDate = new Date();

      this.historialItems = [
        {
          title: monthsString.JUL,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.JUL],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.JUL),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.JUL
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.JUL,
            currentDate
          ),
        },
        {
          title: monthsString.AGO,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.AGO],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.AGO),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.AGO
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.AGO,
            currentDate
          ),
        },
        {
          title: monthsString.SEPT,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.SEPT],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.SEPT),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.SEPT
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.SEPT,
            currentDate
          ),
        },
        {
          title: monthsString.OCT,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.OCT],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.OCT),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.OCT
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.OCT,
            currentDate
          ),
        },
        {
          title: monthsString.NOV,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.NOV],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.NOV),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.NOV
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.NOV,
            currentDate
          ),
        },
        {
          title: monthsString.DIC,
          subtitle: years[0],
          monthNumber: monthsNumber[monthsString.DIC],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.DIC),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.DIC
          ),
          isDisabled: this.isFutureSelection(
            years[0],
            monthsNumber.DIC,
            currentDate
          ),
        },
        {
          title: monthsString.ENE,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.ENE],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.ENE),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.ENE
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.ENE,
            currentDate
          ),
        },
        {
          title: monthsString.FEB,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.FEB],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.FEB),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.FEB
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.FEB,
            currentDate
          ),
        },
        {
          title: monthsString.MAR,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.MAR],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.MAR),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.MAR
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.MAR,
            currentDate
          ),
        },
        {
          title: monthsString.ABR,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.ABR],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.ABR),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.ABR
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.ABR,
            currentDate
          ),
        },
        {
          title: monthsString.MAY,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.MAY],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.MAY),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.MAY
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.MAY,
            currentDate
          ),
        },
        {
          title: monthsString.JUN,
          subtitle: years[1],
          monthNumber: monthsNumber[monthsString.JUN],
          isChecked: periods.some((x) => x.monthNumber === monthsNumber.JUN),
          isBlocked: blockedPeriods.some(
            (x) => x.monthNumber === monthsNumber.JUN
          ),
          isDisabled: this.isFutureSelection(
            years[1],
            monthsNumber.JUN,
            currentDate
          ),
        },
      ];
    },
    isFutureSelection(fiscalYear, monthNumber, currentDate) {
      const tempDate = new Date(fiscalYear, monthNumber, 1);
      return currentDate < tempDate;
    },
    async onUpdateDocument(newVal) {
      this.infoData.documents = this.infoData.documents.map((doc) =>
        doc.documentTypeId === newVal.documentTypeId
          ? {
              ...doc,
              documentFilename: newVal.documentFilename,
              documentId: newVal.documentId,
              requestDocumentId: newVal.requestDocumentId,
              file: newVal.file,
            }
          : doc
      );
      if (this.wasFormValidated)
        this.$nextTick(async () => await this.$refs.documentFormRef.validate());
    },
    async submit() {
      this.wasFormValidated = true;
      if (!this.historialItems.some((x) => x.isChecked)) {
        this.ShowToast(
          'Alerta',
          'Favor seleccionar un periodo a reembolsar.',
          'error'
        );
        return false;
      }

      const isValid = await this.$refs.formRef.validate();
      const docFormIsValid = await this.$refs.documentFormRef.validate();

      if (!isValid || !docFormIsValid) {
        this.ShowToast(
          'Alerta',
          'Favor completar la información solicitada.',
          'error'
        );
        return false;
      }
      let message = '';
      let comment = '';
      let status = null;
      if (this.infoData.statusId == disbursementRequestStatuses.Nuevo) {
        message = `¿Está seguro(a) que desea someter la solicitud de reembolso?`;
        comment = 'Información de solicitud ha sido sometida.';
        status = disbursementRequestStatuses.Sometido;
      } else if (
        this.infoData.statusId == disbursementRequestStatuses.DevueltoARevisión
      ) {
        message = `¿Está seguro(a) que desea someter la revisión de la solicitud de reembolso  ${this.infoData.requestNumber}`;
        comment = 'Información de solicitud ha sido revisada.';
        status = disbursementRequestStatuses.SometidoRevisado;
      }
      const answer = await this.ShowCancelModal(
        'Confirmación',
        message,
        'Sí',
        'No'
      );

      if (!answer) return false;

      try {
        const tapRequestId = this.infoData.selectedServices[0]?.tapRequestId;

        let payload = serialize(
          {
            preRequest: {
              tapRequestId: tapRequestId,
              requestTypeId: 1,
              ssn: this.$route.params.ssn,
            },
            request: {
              requestId: this.infoData.requestId,
              tapRequestId: tapRequestId,
              periods: this.checkPeriods.map((p) => ({
                requestId: this.infoData.requestId,
                monthNumber: p.monthNumber,
              })),
              selectedServices: this.infoData.selectedServices,
              amount: this.totalAmount,
              comment: this.infoData.comment,
              statusComment: comment,
              newDisbursementRequestStatus: status,
              file: this.infoData.selectedServices[0].file,
            },
          },
          {
            indices: true,
            nullsAsUndefineds: true,
            dotsForObjectNotation: true,
          }
        );

        const files = this.infoData.documents.filter((x) => x.file != null);

        (files || []).forEach((document, index) => {
          payload.append(`files[${index}].file`, document.file);
          payload.append(
            `files[${index}].documentTypeId`,
            document.documentTypeId
          );
        });

        const { data: submitedData } = await _submitRequest(payload);

        if (submitedData.isValid) {
          this.infoData.periods = this.checkPeriods.map((p) => ({
            ...p,
            periodName: `${p.title} ${p.subtitle}`,
          }));
          this.infoData.statusId = status;
          this.infoData.statusName = status ? '' : '';
          if (status == disbursementRequestStatuses.SometidoRevisado) {
            this.infoData.statusName = STATUS_NAME.SometidoRevisado;
          }
          if (status == disbursementRequestStatuses.Sometido) {
            this.infoData.statusName = STATUS_NAME.sometido;
          }
          this.ShowToast(
            'Proceso Exitoso.',
            'La solicitud de reembolso ha sido sometida exitosamente.',
            'success'
          );
          const { data: requestDetailsData } = await getRequestDetails(
            submitedData.requestId
          );
          this.infoData = requestDetailsData;
        } else {
          this.ShowToast('Alerta', submitedData.message, 'error');
        }
      } catch (err) {
        this.ShowToast('Alerta', err.response.data.message || 'error', 'error');
      }
    },
  },
};
</script>
<style lang="scss">
.color-yellow-star {
  color: #f8b539;
}
.color-white {
  color: #f8f8f8 !important;
}
</style>
<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';
.summary-dot {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  background: #ff7058 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 9px;
  padding: 22px !important;
  border: 3px solid #fff;
}
.orange-icon {
  color: #ffffff;
  font-size: 21pt;
  padding-right: 4px;
  line-height: 25px;
  margin-left: 4px;
}

.orange-icon-badge-align {
  vertical-align: top;
}
.round-element {
  background-color: #ffffff !important;
  border-radius: 50px;
  border: 1px solid #aabbb4;
  padding: 15px;
  width: 90px;
  height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-border {
  border-radius: 4px;
}
::v-deep .input-group-text {
  background-color: map-get($colors, 'green');
  border-color: map-get($colors, 'green');
}
.jumbo {
  border-radius: 5px;
}
.container-reason {
  margin-top: 17px;
  border-radius: 4px;
  padding: 35px;
  background-color: #ffebeb;

  border-left: 5px solid coral;
  p {
    color: #11291b;
  }
}
@media (max-width: 375px) {
  .student-search-alert-error.no-border p {
    font-size: 12px;
  }
}
.student-search-alert-error.alert p,
.student-search-alert-error.alert em {
  color: #af6708;
}
.student-search-alert-error.alert {
  background: #fff5e8;
  border: 1px solid #fdac41;
}
.custom-circle-loader {
  stroke: #e5f5ef !important;
}
.green-color {
  color: map-get($colors, 'green');
}
.custom-icon {
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
}
</style>
