var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.items),function(item){return _c('div',{key:("cost-result-" + (item.id)),staticClass:"rounded p-0 mb-2",class:_vm.serviceRowStyles(item)},[_c('div',{staticClass:"row mx-auto",class:{
        'd-flex align-items-center': !_vm.isMobile,
      }},[_c('div',{class:{
          'col-2 px-4 py-2': !_vm.isMobile,
          'col py-2': _vm.isMobile,
        }},[_c('p',{staticClass:"color-gray font-14 mb-0 pl-2"},[_vm._v("Servicio:")]),_c('p',{staticClass:"color-black font-16 mb-0 pl-2",class:_vm.isServiceDisabled(item) && !_vm.isReadOnly
              ? 'color-secondary-7'
              : 'color-black'},[_vm._v(" "+_vm._s(item.serviceName)+" ")])]),_c('div',{class:{
          'col-1 row mx-auto px-5 py-2': !_vm.isMobile,
          'col py-2': _vm.isMobile,
        }},[_c('div',{class:{ 'col-6': !_vm.isMobile }},[_c('p',{staticClass:"color-gray font-14 mb-0"},[_vm._v("Costo:")]),_c('p',{staticClass:"font-16 mb-0",class:_vm.isServiceDisabled(item) && !_vm.isReadOnly
                ? 'color-secondary-7'
                : 'color-black'},[_vm._v(" "+_vm._s(_vm._f("money")(item.cost))+" ")])])]),(_vm.isTherapyService(item) && item.checked)?_c('div',{class:{
          'col-3 row mx-auto px-5 py-2': !_vm.isMobile,
          'col py-2': _vm.isMobile,
        }},[_c('div',{class:{ 'col': !_vm.isMobile }},[_c('p',{staticClass:"color-gray font-14 mb-0"},[_vm._v("Intervenciones:")]),_c('p',{staticClass:"font-16 mb-0",class:_vm.isServiceDisabled(item) && !_vm.isReadOnly
                ? 'color-secondary-7'
                : 'color-black'},[_c('validation-provider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [(!_vm.readOnly)?_c('custom-input',{attrs:{"value":item.visitCount,"custom-error-msg":"La cantidad de intervenciones es requerida.","type":"number","error":errors[0]},on:{"input":function($event){return _vm.onVisitCountChange(item, $event)}}}):_vm._e()]}}],null,true)}),(_vm.readOnly)?_c('span',[_vm._v(_vm._s(item.visitCount))]):_vm._e()],1)])]):_vm._e(),(_vm.isTherapyService(item) && item.checked)?_c('div',{class:{
          'col-2 row mx-auto px-5 py-2': !_vm.isMobile,
          'col py-2': _vm.isMobile,
        }},[_c('div',{class:{ col: !_vm.isMobile }},[_c('p',{staticClass:"color-gray font-14 mb-0"},[_vm._v("Costo Total:")]),_c('p',{staticClass:"font-16 mb-0",class:_vm.isServiceDisabled(item) && !_vm.isReadOnly
                ? 'color-secondary-7'
                : 'color-black'},[_vm._v(" "+_vm._s(_vm._f("money")((item.cost * item.visitCount)))+" ")])])]):_vm._e(),(_vm.isTherapyService(item) && item.checked)?_c('div',{class:{
          'col row mx-auto px-5 py-2': !_vm.isMobile,
          'col py-2': _vm.isMobile,
        }},[_c('div',{class:{ col: !_vm.isMobile }},[_c('p',{staticClass:"color-gray font-14 mb-0"},[_vm._v("Certificación de Servicios:")]),_c('p',{staticClass:"font-16 mb-0",class:_vm.isServiceDisabled(item) && !_vm.isReadOnly
                ? 'color-secondary-7'
                : 'color-black'},[(!item.document)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var validate = ref.validate;
                var errors = ref.errors;
return [(!item.document)?_c('custom-file-input',{ref:"fileUpload",refInFor:true,staticClass:"custom-document-input",attrs:{"accept":".pdf","custom-error-msg":"Por favor incluya un archivo documentando las intervenciones ocurridas.","display-style":"normal","label":"","on-download":function () {},"error":errors[0],"required":""},on:{"input":function($event){_vm.onServiceFileChange(item, $event) || validate($event)},"after-remove":function($event){return _vm.onServiceFileRemoved(item)}}}):_vm._e()]}}],null,true)}):_vm._e(),(item.document)?_c('action-button',{attrs:{"i":"fas fa-download","text":"Descargar","variant":"success download-btn px-3"},on:{"click":function($event){return _vm.onDownload(item.document.documentId)}}}):_vm._e(),(item.document && !_vm.readOnly)?_c('action-button',{attrs:{"i":"fas fa-undo-alt","text":"Reemplazar","variant":"outline-success download-btn ml-3"},on:{"click":function($event){return _vm.onReplaceDocument(item)}}}):_vm._e()],1)])]):_vm._e(),(!_vm.isReadOnly)?_c('div',{staticClass:"check-box",class:_vm.checkboxStyles(item)},[_c('check-box',{staticClass:"d-flex justify-content-center align-items-center p-0",class:_vm.checkboxStyles(item),attrs:{"disabled":_vm.isServiceDisabled(item)},on:{"input":function($event){return _vm.onSelectService({
              serviceId: item.serviceId,
              checked: $event,
              cost: item.cost,
              visitCount: item.visitCount,
              file: item.file,
              tapCentralServiceId: item.id,
              serviceCategoryId: item.serviceCategoryId,
              tapRequestId: item.requestId,
            })}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1):_vm._e()])])}),(!_vm.isReadOnly)?_c('div',{staticClass:"service-total rounded bg-secondary-12 d-flex justify-content-end align-items-center px-3 py-2"},[_c('span',{staticClass:"color-black font-16"},[_vm._v(" Monto a reembolsar para el periodo seleccionado: ")]),_c('span',{staticClass:"color-black font-16 font-weight-bold pl-2"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.totalAmount))+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }