<template>
  <div
    class="
      mx-auto
      row
      documents-container
      jumbo
      mt-3
      p-3
      bg-light
      d-flex
      align-items-center
    "
  >
    <div class="col-9 row mx-auto">
      <div class="col row mx-auto">
        <label class="font-14 color-gray col-12">Documento:</label>
        <label class="font-16 color-black col-12">
          {{ item.documentTypeName | empty }}
        </label>
      </div>
      <div class="col row mx-auto">
        <div v-if="item.documentFilename">
          <label class="font-14 color-gray col-12">Archivo:</label>
          <label class="font-16 color-black col-12">
            {{ item.documentFilename | empty }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-3 row mx-auto">
      <div
        class="row col-12 row payment-upload justify-content-end"
        v-if="item.documentTypeId === documentTypes.PaymentCertificate"
      >
        <div
          class="row col-12 justify-content-end"
          v-if="!item.documentFilename && !isMobile"
        >
          <custom-file-input
            id="paymentFileUpload"
            ref="paymentFileUpload"
            v-model="paymentfile"
            accept=".pdf"
            class="custom-document-input col-12"
            display-style="normal"
            @input="uploadDocument(item.documentTypeId)"
            :on-download="() => {}"
          />
        </div>
        <div
          class="row mx-auto col-12 justify-content-end"
          v-if="item.documentFilename"
        >
          <action-button
            i="fas fa-download"
            text="Descargar"
            variant="success download-btn px-3"
            @click="onDownload(item.documentId)"
          />
          <action-button
            i="fas fa-undo-alt"
            text="Reemplazar"
            variant="outline-success download-btn ml-3"
            @click="onReplaceDocument(item.documentTypeId)"
          />
          <input
            type="file"
            id="paymentFileUploadHidden"
            ref="paymentFileUploadHidden"
            accept=".pdf"
            v-show="false"
            :attachment-id="item.documentId"
            @change="updateFileChange($event, item.documentTypeId)"
          />
        </div>
      </div>
      <div
        class="col-12 row evidence-upload d-flex justify-content-end"
        v-if="item.documentTypeId === documentTypes.EvidencePaymentReceipt"
      >
        <div
          class="row col-12 d-flex justify-content-end"
          v-if="!item.documentFilename && !isMobile"
        >
          <custom-file-input
            id="evidenceFileUpload"
            ref="evidenceFileUpload"
            v-model="evidencefile"
            accept=".pdf"
            class="custom-document-input col"
            display-style="normal"
            @input="uploadDocument(item.documentTypeId)"
            :on-download="() => {}"
          />
        </div>
        <div
          class="row mx-auto col-12 justify-content-end"
          v-if="item.documentFilename"
        >
          <action-button
            i="fas fa-download"
            text="Descargar"
            variant="success download-btn px-3"
            @click="onDownload(item.documentId)"
          />
          <action-button
            i="fas fa-undo-alt"
            text="Reemplazar"
            variant="outline-success download-btn ml-3"
            @click="onReplaceDocument(item.documentTypeId)"
          />
          <input
            type="file"
            id="evidenceFileUploadHidden"
            ref="evidenceFileUploadHidden"
            accept=".pdf"
            v-show="false"
            @change="updateFileChange($event, item.documentTypeId)"
          />
        </div>
      </div>
      <div
        class="col-12 row other-upload d-flex justify-content-end"
        v-if="item.documentTypeId === documentTypes.Other"
      >
        <div
          class="row col-12 d-flex justify-content-end"
          v-if="!item.documentFilename && !isMobile"
        >
          <custom-file-input
            id="otherFileUpload"
            ref="otherFileUpload"
            v-model="otherfile"
            accept=".pdf"
            class="custom-document-input col"
            display-style="normal"
            @input="uploadDocument(item.documentTypeId)"
            :on-download="() => {}"
          />
        </div>
        <div
          class="row mx-auto col-12 justify-content-end"
          v-if="item.documentFilename"
        >
          <action-button
            i="fas fa-download"
            text="Descargar"
            variant="success download-btn px-3"
            @click="onDownload(item.documentId)"
          />
          <action-button
            i="fas fa-undo-alt"
            text="Reemplazar"
            variant="outline-success download-btn ml-3"
            @click="onReplaceDocument(item.documentTypeId)"
          />
          <input
            type="file"
            id="otherFileUploadHidden"
            ref="otherFileUploadHidden"
            accept=".pdf"
            v-show="false"
            @change="updateFileChange($event, item.documentTypeId)"
          />
        </div>
      </div>
    </div>
    <div
      class="row col-12 d-flex justify-content-end"
      v-if="
        !item.documentFilename &&
          isMobile &&
          item.documentTypeId === documentTypes.PaymentCertificate
      "
    >
      <custom-file-input
        id="paymentFileUpload"
        ref="paymentFileUpload"
        v-model="paymentfile"
        accept=".pdf"
        class="custom-document-input col"
        display-style="normal"
        @input="uploadDocument(item.documentTypeId)"
        :on-download="() => {}"
      />
    </div>
    <div
      class="row col-12 d-flex justify-content-end"
      v-if="
        !item.documentFilename &&
          isMobile &&
          item.documentTypeId === documentTypes.EvidencePaymentReceipt
      "
    >
      <custom-file-input
        id="evidenceFileUpload"
        ref="evidenceFileUpload"
        v-model="evidencefile"
        accept=".pdf"
        class="custom-document-input col"
        display-style="normal"
        @input="uploadDocument(item.documentTypeId)"
        :on-download="() => {}"
      />
    </div>
    <div
      class="row col-12 d-flex justify-content-end"
      v-if="
        !item.documentFilename &&
          isMobile &&
          item.documentTypeId === documentTypes.Other
      "
    >
      <custom-file-input
        id="otherFileUpload"
        ref="otherFileUpload"
        v-model="otherfile"
        accept=".pdf"
        class="custom-document-input col"
        display-style="normal"
        @input="uploadDocument(item.documentTypeId)"
        :on-download="() => {}"
      />
    </div>
  </div>
</template>
<script>
import CustomFileInput from 'CustomFileInput';
import ActionButton from 'ActionButton';
import DocumentTypes from '@/utils/constants/documentTypes';
import { uploadOrUpdateFile as _uploadOrUpdateFile } from '@/services/api/disbursement.api.js';
import documentService from '@/services/documents';
import { serialize } from 'object-to-formdata';
import alert from '@/mixins/alert.mixin';
import storeMixin from '@quisit/mipe-utils/mixins/storeGenerator';

export default {
  name: 'ReinbursementDocuments',
  components: {
    CustomFileInput,
    ActionButton,
  },
  mixins: [alert, storeMixin],
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentfile: null,
      evidencefile: null,
      otherfile: null,
    };
  },
  computed: {
    documentTypes() {
      return DocumentTypes;
    },
  },
  methods: {
    async onDownload(documentId) {
      await documentService.downloadFromServer(documentId);
    },
    async uploadDocument(documentTypeId) {
      let documentUpdated = {
        requestId: this.requestId,
        documentTypeId,
        file: null,
      };

      switch (documentTypeId) {
        case this.documentTypes.PaymentCertificate:
          documentUpdated.file = this.paymentfile;
          break;
        case this.documentTypes.EvidencePaymentReceipt:
          documentUpdated.file = this.evidencefile;
          break;
        case this.documentTypes.Other:
          documentUpdated.file = this.otherfile;
          break;
      }
      if (!documentUpdated.file) {
        this.$emit('on-refresh-data', {
          documentTypeId: documentTypeId,
          file: null,
        });
        return;
      }

      if (this.requestId > 0) {
        let payload = serialize(documentUpdated, {
          indices: true,
          nullsAsUndefineds: true,
        });

        await _uploadOrUpdateFile(payload).then(({ data }) => {
          if (data) {
            this.$emit('on-refresh-data', data);
          }
        });
      } else {
        this.$emit('on-refresh-data', {
          documentTypeId: documentTypeId,
          file: documentUpdated.file,
        });
      }
    },
    onReplaceDocument(documentTypeId) {
      switch (documentTypeId) {
        case this.documentTypes.PaymentCertificate:
          document.getElementById('paymentFileUploadHidden').click();
          break;
        case this.documentTypes.EvidencePaymentReceipt:
          document.getElementById('evidenceFileUploadHidden').click();
          break;
        case this.documentTypes.Other:
          document.getElementById('otherFileUploadHidden').click();
          break;
      }
    },
    async updateFileChange(e, documentTypeId) {
      if (e.target.files && e.target.files.length > 0) {
        const selectedFile = Array.from(e.target.files)?.map((file) => file);
        let payload = serialize(
          {
            requestId: this.requestId,
            documentTypeId,
            file: selectedFile[0],
          },
          { indices: true, nullsAsUndefineds: true }
        );
        await _uploadOrUpdateFile(payload).then(({ data }) => {
          if (data) {
            this.ShowToast(
              '',
              'El documento ha sido reemplazado exitosamente.',
              'success'
            );
            this.$emit('on-refresh-data', data);
          }
        });
      }
    },
  },
};
</script>
