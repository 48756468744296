<template>
  <div>
    <div
      v-for="item of items"
      :key="`cost-result-${item.id}`"
      class="rounded p-0 mb-2"
      :class="serviceRowStyles(item)"
    >
      <div
        class="row mx-auto"
        :class="{
          'd-flex align-items-center': !isMobile,
        }"
      >
        <div
          :class="{
            'col-2 px-4 py-2': !isMobile,
            'col py-2': isMobile,
          }"
        >
          <p class="color-gray font-14 mb-0 pl-2">Servicio:</p>
          <p
            class="color-black font-16 mb-0 pl-2"
            :class="
              isServiceDisabled(item) && !isReadOnly
                ? 'color-secondary-7'
                : 'color-black'
            "
          >
            {{ item.serviceName }}
          </p>
        </div>
        <div
          :class="{
            'col-1 row mx-auto px-5 py-2': !isMobile,
            'col py-2': isMobile,
          }"
        >
          <div :class="{ 'col-6': !isMobile }">
            <p class="color-gray font-14 mb-0">Costo:</p>
            <p
              class="font-16 mb-0"
              :class="
                isServiceDisabled(item) && !isReadOnly
                  ? 'color-secondary-7'
                  : 'color-black'
              "
            >
              {{ item.cost | money }}
            </p>
          </div>
        </div>

        <div
          v-if="isTherapyService(item) && item.checked"
          :class="{
            'col-3 row mx-auto px-5 py-2': !isMobile,
            'col py-2': isMobile,
          }"
        >
          <div :class="{ 'col': !isMobile }">
            <p class="color-gray font-14 mb-0">Intervenciones:</p>
            <p
              class="font-16 mb-0"
              :class="
                isServiceDisabled(item) && !isReadOnly
                  ? 'color-secondary-7'
                  : 'color-black'
              "
            >
              <validation-provider v-slot="{ errors }" rules="required|min_value:1">
                <custom-input
                  v-if="!readOnly"
                  :value="item.visitCount"
                  @input="onVisitCountChange(item, $event)"
                  custom-error-msg="La cantidad de intervenciones es requerida."
                  type="number"
                  :error="errors[0]"
                />
              </validation-provider>
              <span v-if="readOnly">{{ item.visitCount }}</span>
            </p>
          </div>
        </div>

        <div
          v-if="isTherapyService(item) && item.checked"
          :class="{
            'col-2 row mx-auto px-5 py-2': !isMobile,
            'col py-2': isMobile,
          }"
        >
          <div :class="{ col: !isMobile }">
            <p class="color-gray font-14 mb-0">Costo Total:</p>
            <p
              class="font-16 mb-0"
              :class="
                isServiceDisabled(item) && !isReadOnly
                  ? 'color-secondary-7'
                  : 'color-black'
              "
            >
              {{ (item.cost * item.visitCount) | money }}
            </p>
          </div>
        </div>

        <div
          v-if="isTherapyService(item) && item.checked"
          :class="{
            'col row mx-auto px-5 py-2': !isMobile,
            'col py-2': isMobile,
          }"
        >
          <div :class="{ col: !isMobile }">
            <p class="color-gray font-14 mb-0">Certificación de Servicios:</p>
            <p
              class="font-16 mb-0"
              :class="
                isServiceDisabled(item) && !isReadOnly
                  ? 'color-secondary-7'
                  : 'color-black'
              "
            >
              <validation-provider v-slot="{ validate, errors }" rules="required" v-if="!item.document">
                <custom-file-input
                  v-if="!item.document"
                  ref="fileUpload"
                  
                  @input="onServiceFileChange(item, $event) || validate($event)"
                  @after-remove="onServiceFileRemoved(item)"
                  accept=".pdf"
                  class="custom-document-input"
                  custom-error-msg="Por favor incluya un archivo documentando las intervenciones ocurridas."
                  display-style="normal"
                  label=""
                  :on-download="() => {}"
                  :error="errors[0]"
                  required
                />
              </validation-provider>
              <action-button
                v-if="item.document"
                i="fas fa-download"
                text="Descargar"
                variant="success download-btn px-3"
                @click="onDownload(item.document.documentId)"
              />
              <action-button
                v-if="item.document && !readOnly"
                i="fas fa-undo-alt"
                text="Reemplazar"
                variant="outline-success download-btn ml-3"
                @click="onReplaceDocument(item)"
              />
            </p>
          </div>
        </div>

        <div v-if="!isReadOnly" class="check-box" :class="checkboxStyles(item)">
          <check-box
            class="d-flex justify-content-center align-items-center p-0"
            :class="checkboxStyles(item)"
            :disabled="isServiceDisabled(item)"
            v-model="item.checked"
            @input="
              onSelectService({
                serviceId: item.serviceId,
                checked: $event,
                cost: item.cost,
                visitCount: item.visitCount,
                file: item.file,
                tapCentralServiceId: item.id,
                serviceCategoryId: item.serviceCategoryId,
                tapRequestId: item.requestId,
              })
            "
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isReadOnly"
      class="service-total rounded bg-secondary-12 d-flex justify-content-end align-items-center px-3 py-2"
    >
      <span class="color-black font-16">
        Monto a reembolsar para el periodo seleccionado:
      </span>
      <span class="color-black font-16 font-weight-bold pl-2">
        {{ totalAmount | money }}
      </span>
    </div>
  </div>
</template>

<script>
import ActionButton from 'ActionButton';
import CheckBox from 'CheckBox';
import CustomInput from 'CustomInput';
import CustomFileInput from 'CustomFileInput';
import documentService from '@/services/documents';

export default {
  name: 'RequestServices',
  components: {
    ActionButton,
    CheckBox,
    CustomInput,
    CustomFileInput,
  },
  filters: {
    money(value) {
      return '$' + (Math.round(value * 100) / 100).toFixed(2);
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: [String, Boolean],
      default: () => '',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.selectedItems.forEach((e) => {
      this.onSelectService({
        id: e.id,
        tapCentralServiceId: e.tapCentralServiceId,
        serviceId: e.serviceId,
        checked: true,
        cost: e.cost,
        visitCount: e.visitCount,
        file: null,
        document: e.document,
        serviceCategoryId: e.serviceCategoryId,
      });
      let item = this.items.find((i) => i.id == e.tapCentralServiceId);
      item.visitCount = e.visitCount;
      item.document = e.document;
      item.checked = true;
    });
  },
  data() {
    return {
      selectedServices: [],
    };
  },
  computed: {
    isReadOnly() {
      return this.readOnly;
    },
    totalAmount() {
      const serviceCosts = this.selectedServices.map((x) =>
        this.isTherapyService(x)
          ? Number(x.cost) * Number(x.visitCount) ?? 0
          : Number(x.cost ?? 0)
      );
      const total = serviceCosts.reduce((a, b) => a + b, 0);
      this.$emit('update-amount', total);
      return total;
    },
    isServiceDisabled() {
      return (item) => {
        return this.items.some((x) => x.id === item.id && !x.isBillable);
      };
    },
    serviceRowStyles() {
      return (item) => {
        const isBlocked = this.isServiceDisabled(item);
        let styles = '';

        if (isBlocked && !this.isReadOnly) {
          styles += ' bg-secondary-11';
        } else {
          styles += ' bg-white';
        }

        if (!this.isReadOnly) {
          styles += ' service-option-border';
        }

        return styles;
      };
    },
    checkboxStyles() {
      return (item) => {
        const isDisabled = this.isServiceDisabled(item);
        const isChecked = this.selectedServices.find(
          (x) => x.id === item.id && x.checked
        );
        let styles = '';

        if (isChecked) {
          styles += ' bg-gray-14';
        }
        if (isDisabled) {
          styles += ' disabled-check';
        }
        if (!this.isMobile) {
          styles += ' py-3 px-4';
        } else {
          styles += ' px-2';
        }

        return styles;
      };
    },
  },
  watch: {
    selectedServices() {
      this.$emit('on-select-services', this.selectedServices);
    },
  },
  methods: {
    onReplaceDocument(item) {
      this.removeDocumentData(item);
    },
    onServiceFileRemoved(item) {
      this.removeDocumentData(item);
    },
    removeDocumentData(item) {
      item.document = null;
      let selectedService = this.selectedServices.find(
        (s) => s.tapCentralServiceId == item.id
      );
      selectedService.file = null;
      selectedService.document = null;
      this.$emit('on-select-services', this.selectedServices);
    },
    onServiceFileChange(item, file) {
      let selectedService = this.selectedServices.find(
        (s) => s.tapCentralServiceId == item.id
      );
      selectedService.file = file;
      this.$emit('on-select-services', this.selectedServices);
    },
    onVisitCountChange(item, event) {
      let selectedService = this.selectedServices.find(
        (s) => s.tapCentralServiceId == item.id
      );
      item.visitCount = parseInt(event);
      selectedService.visitCount = parseInt(event);
      this.$emit('on-select-services', this.selectedServices);
    },
    async onDownload(documentId) {
      await documentService.downloadFromServer(documentId);
    },
    onSelectService(selection) {
      const isSelected = this.selectedServices.some(
        (x) => x.tapCentralServiceId === selection.tapCentralServiceId
      );

      if (selection.checked && !isSelected) {
        this.selectedServices.push({ ...selection });
      } else if (!selection.checked && isSelected) {
        this.selectedServices = this.selectedServices.filter(
          (x) => x.tapCentralServiceId !== selection.tapCentralServiceId
        );
      }
    },
    isTherapyService(item) {
      return item.serviceCategoryId == 2;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables/colors.scss';

.service-option-border {
  border: 1px solid map-get($colors, 'gray-13');
}
.check-box {
  display: flex;
  justify-content: end;
  align-items: center;
  border-left: 1px solid map-get($colors, 'gray-13');
}
::v-deep .input-group .option-input + label span {
  margin: 0 !important;
}
::v-deep .disabled-check .input-group .option-input + label span {
  margin: 0 !important;
  background-color: map-get($colors, 'secondary-5') !important;
}
</style>
